
import { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    only: {
      type: Array,
      default: () => [],
    } as PropOptions<string[]>,
    except: {
      type: Array,
      default: () => [],
    } as PropOptions<string[]>,
  },
  computed: {
    ...mapGetters('context', ['locale']),
    isLocaleAcceptable() {
      if (this.only.length) {
        return this.only.includes(this.locale)
      }

      return !this.except.includes(this.locale)
    },
  },
}

export const ArabicLocales = [
  'ar-ae',
  'ar-bh',
  'ar-kw',
  'ar-om',
  'ar-qa',
  'ar-sa',
  'en-bh',
  'en-kw',
  'en-om',
  'en-qa',
  'en-sa',
  'en-ae',
]
