
import { Hooper, Slide } from 'hooper'
import { mapGetters } from 'vuex'
import { CdpContainer } from '~/components/renderers'
import LocaleOnly, {
  ArabicLocales,
} from '~/components/utils/locale-only/index.vue'
import 'hooper/dist/hooper.css'

const CDP_CONTAINER_ID = 'cdp-header-all-carousel'

export default {
  name: 'Carousel',
  components: {
    Hooper,
    Slide,
    LocaleOnly,
    CdpContainer,
  },
  data() {
    return {
      ArabicLocales,
      CDP_CONTAINER_ID,
      isReady: false,
      cdpWasInjected: false,
      carouselIsAvailable: false,
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        playSpeed: 5000,
        vertical: true,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
        hoverPause: false,
      },
    }
  },
  computed: {
    ...mapGetters('context', ['trustpilotLocale', 'direction']),
    trustpilotIdentifier() {
      return this.$mq === 'sm'
        ? `header-mobile-${this.direction}`
        : `header-desktop-${this.direction}`
    },
  },
  mounted() {
    this.isReady = true
    if (this.$mq === 'sm') {
      const observer = new MutationObserver(this.cdpContainerMutated)
      observer.observe(this.$el.querySelector(`#${CDP_CONTAINER_ID}`), {
        childList: true,
        subtree: true,
      })
      this.observer = observer
      this.wasMounted = true
      this.carouselIsAvailable = true
    }
  },
  beforeDestroy() {
    if (this.observer && this.carouselIsAvailable) this.observer.disconnect()
  },
  methods: {
    cdpContainerMutated() {
      const cdpContent = this.$el.querySelector(`#${CDP_CONTAINER_ID}`)
        .innerHTML
      if (cdpContent) {
        this.cdpWasInjected = true

        setTimeout(() => {
          this.moveCdpContainer()
        }, 500)
        this.observer.disconnect()
      }
    },
    moveCdpContainer() {
      const cdpContent = this.$el.querySelector(`#${CDP_CONTAINER_ID}`)
      const cdpPlaceholder = this.$el.querySelector(`#cdpHeaderPlaceholder`)
      cdpPlaceholder.append(cdpContent)
    },
  },
}
